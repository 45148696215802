.brands-carousel {
    margin: 0 auto;
    max-width: 600px;
    padding: 0 20px;
}

.brands-wrapper {
    margin-top: 32px;
}

.brands-wrapper-title {
    text-align: center;
}

.brands-wrapper .ant-carousel .slick-dots li button {
    background: #3498db;
    opacity: 0.4;
}

.brands-wrapper .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: #3498db;
}

.brands-wrapper .ant-carousel .slick-dots-bottom {
    bottom: -10px;
}

@media (max-width: 768px) {
    /* Styles specific to mobile devices */
    .brands-carousel {
        padding: 0 10px;
    }

    .brands-wrapper {
        margin-top: 16px;
    }

    .brands-wrapper .ant-carousel .slick-dots li button {
        opacity: 0.6;
    }

    .brands-wrapper .ant-carousel .slick-dots li.slick-active button {
        opacity: 1;
    }

    .brands-wrapper .ant-carousel .slick-dots-bottom {
        bottom: -5px;
    }
}
