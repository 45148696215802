.whatsapp-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .whatsapp-button:hover {
    background-color: #128c7e;
  }
  
  .whatsapp-icon {
    font-size: 80px;
  }
  
  @media (max-width: 768px) {
    .whatsapp-button {
      padding: 12px;
    }
  
    .whatsapp-icon {
      font-size: 80px;
    }
  }
  