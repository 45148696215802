.page-theme {
    margin: 0 400px;
    padding-top: 32px;
}

.page-theme img {
    width: 100%;
}

@media (max-width: 768px) {
    /* Styles specific to mobile devices */
    .page-theme {
        margin: 0 20px;
        padding-top: 16px;
    }
}
