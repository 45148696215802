.form-item {
    align-items: baseline;
}

.form-item .anticon {
    float: right;
    margin-right: 10px;
    margin-top: 3px;
}

.social-login-wrapper {
    width: 100%;
    margin-top: 20px;
}
