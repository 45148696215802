.email-chat-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 999;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 800px; /* Set the desired width */
  }
  
  .email-icon {
    background-color: red;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .email-icon:hover {
    transform: scale(1.1);
  }
  
  .close-icon {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #fff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  form {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 100%; /* Set the desired width */
  }
  
  /* Additional CSS if needed */
  label {
    display: block;
    margin-bottom: 20px;
  }
  
  input[type='text'],
  input[type='email'],
  textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }
  
  button[type='submit'] {
    background-color: lightblue;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button[type='submit']:hover {
    background-color: #0099ff;
  }
  
  .open {
    transform: translateX(0);
  }
  
  @media screen and (max-width: 767px) {
    .email-chat-container {
      bottom: 10px;
      right: 10px;
      width: 100%; /* Adjust the width to fit the mobile screen */
      max-width: 500px; /* Set a maximum width for better readability on small screens */
    }
  
    .email-icon {
      width: 100px; /* Increase the icon size for better visibility on small screens */
      height: 100px;
    }
  }
  