.slider-brand-item {
    position: relative;
}

.slider-brand-item-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
