.ant-alert {
    margin-bottom: 16px;
}

.ant-rate {
    font-size: 12px !important;
}

@media (max-width: 768px) {
    /* Styles specific to mobile devices */
    .ant-alert {
        margin-bottom: 12px;
    }

    .ant-rate {
        font-size: 10px !important;
    }
}
