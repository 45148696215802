.login-content {
    background-color: white;
    padding: 50px;
    margin: 64px 20px;
}

.ant-checkbox-group-item {
    display: block;
    margin-right: 0;
}
