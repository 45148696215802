.perfume-cards-slider {
    margin: 32px 400px 85px;
}

.perfume-cards-slider-title {
    text-align: center;
}

.perfume-cards-slider .ant-carousel .slick-dots li button {
    background: #3498db;
    opacity: 0.4;
}

.perfume-cards-slider .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: #3498db;
}

.perfume-cards-slider .ant-carousel .slick-dots-bottom {
    bottom: -50px;
}

@media (max-width: 768px) {
    /* Styles specific to mobile devices */
    .perfume-cards-slider {
        margin: 16px 20px;
    }

    .perfume-cards-slider .ant-carousel .slick-dots-bottom {
        bottom: -30px;
    }
}
