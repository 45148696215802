.navbar-wrapper {
  background-color: #0000ff;
  padding: 0;
}

.logo-container {
  height: 100%;
}

.logo {
  height: 150px; /* Adjust the height as needed */
  width: 150px;
  object-fit: contain;
}

.navbar-links {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.navbar-link {
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px;
}

.navbar-link:hover {
  text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .navbar-wrapper {
    padding: 10px;
  }

  .logo {
    height: 100px; /* Adjust the height as needed */
    width: 100px;
  }

  .navbar-links {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .navbar-link {
    padding: 5px;
    font-size: 14px;
  }
}
