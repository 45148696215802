.carousel-item-wrapper {
    position: relative;
}

.carousel-link {
    display: block;
    width: 100%;
    height: 80%;
    position: absolute;
}

@media (max-width: 768px) {
    /* Styles specific to mobile devices */
    .carousel-item-wrapper {
        position: static;
    }

    .carousel-link {
        position: static;
        height: auto;
    }
}
