.product-image-wrapper {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    max-width: 100%;
}

.product-image {
    height: auto;
    max-height: 400px;
    max-width: 100%;
}

.product-header .ant-typography {
    margin: 0 !important;
}

.product-rate {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
}
